// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-detecting-system-theme-mdx": () => import("./../../../src/pages/articles/detecting-system-theme.mdx" /* webpackChunkName: "component---src-pages-articles-detecting-system-theme-mdx" */),
  "component---src-pages-articles-introduction-to-testing-with-jest-mdx": () => import("./../../../src/pages/articles/introduction-to-testing-with-jest.mdx" /* webpackChunkName: "component---src-pages-articles-introduction-to-testing-with-jest-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-midi-output-index-js": () => import("./../../../src/pages/playground/midi-output/index.js" /* webpackChunkName: "component---src-pages-playground-midi-output-index-js" */),
  "component---src-pages-playground-test-js": () => import("./../../../src/pages/playground/test.js" /* webpackChunkName: "component---src-pages-playground-test-js" */)
}

